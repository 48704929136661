import { getCurrentTsgToken, getCurrentTsgId, getState, getFrameworkServiceUrl, getMicroAppVars, getAuthState  } from '@sparky/framework';

import { ZRestResponse, RestAPI, FetchStatus, RestModifiers, RequestMethods, AppAcclMap } from '../data/queryResultsDefinition';
import { buildEnvUrl } from './AppAccelContext';
import { getStrings} from '../l18n/intlTools';

import { makeQueryParams } from './urls';
import { getAppAcclDomain, getADEMDomain, getFawkesCertsDomain, getFawkesDomain } from './utilities';
import Logger from './Logger';

export const enum Products {
  ADEM = 'ADEM',
  ADEM_LICENSE = 'ADEM_LICENSE',
  FAWKES = 'FAWKES',
  FAWKES_JWT = 'FAWKES_JWT'
}

function stringifyResponse(response: Response): string {
  return JSON.stringify({
    url: response.url,
    statusText: response.statusText,
    bodyUsed: response.bodyUsed,
    type: response.type,
    redirected: response.redirected,
    headers: { ...response.headers },
    status: response.status,
  })
}

const buildDefaultError = (response: Response): FetchStatus => {
  const STRs = getStrings() as AppAcclMap<string>;

  return  {
    status: {
      error_message: STRs.serverError,
      http_status: response.status,
      ok: false,
    }
  }
}

const serverError = (response: Response): FetchStatus => {
  const STRs = getStrings() as AppAcclMap<string>;
  Logger.error(stringifyResponse(response as Response));

  const errList: Record<string, string> = {
    '403': STRs.serviceNotInProj,
    '404': STRs.serverDataChanged,
    '500': STRs.serverError
  };

  let errStatus: FetchStatus = { status: {}};
  let responseJson = {}
  const panResponse = response as FetchStatus;
  
  const normalStatusCodes: AppAcclMap<Boolean> = { '302': true, '400': true, '401': true, '403': true, '404': true, '419': true, '500': true}
  
  const statusCode = panResponse?.status?.error_code?.toString() ?? '500';

  if (normalStatusCodes[statusCode]) {
    responseJson = response.json();
    errStatus = responseJson as FetchStatus;
  } else {
    if (! response.bodyUsed) {
      responseJson = response.json();
      errStatus = responseJson as FetchStatus;
      if (!errStatus?.status?.ok) { 
        errStatus = buildDefaultError(response);
      }
    } else {
      errStatus = buildDefaultError(response);
    }
  }

  return errStatus;
}

interface InitRequestParams {
  method: string,
  modifiers?: RestModifiers
  restParams?: string | FormData,
  params?: RestAPI | string,
  url?: string,
}

const makeUrl = (url: string | undefined, component: Products | undefined): string => {
  url = url ?? '';
  let domain: string | undefined = '';

  const authState = getAuthState();
  if (component === undefined) {
    domain = getAppAcclDomain(authState)
    url = buildEnvUrl(url)
  } else if (component === Products.ADEM || component === Products.ADEM_LICENSE) {
    domain = getADEMDomain(authState, component);
  } else if (component === Products.FAWKES_JWT) {
    domain = getFawkesCertsDomain(authState);
  // } else if (component === Products.ADEM || component === Products.ADEM_LICENSE) {
  //   domain = getFawkesDomain(authState)
  }
  domain = domain ?? '';

  return domain + url;
}

const initRequest = async (initParams: InitRequestParams, component: Products | undefined): Promise<RequestInit> => {
  const jwt = await getCurrentTsgToken();

  const ourHeaders: [string, string][] = [
    ['Accept', 'application/json'],
    ['Content-Type', 'application/json'],
    ['Authorization', `Bearer ${jwt}`],
    // ['x-panw-region', `americas`], // TODO: not needed later remove it, only for integration purpose
  ]

  if (component === Products.ADEM || component === Products.ADEM_LICENSE) {
    const tsgId  = getCurrentTsgId();
    ourHeaders.push(['Prisma-Tenant', tsgId as string]);
  }

  if (component === undefined) {
    const region = getState().auth?.instances?.get('logging_service')?.region
    console.log('region', region);
    ourHeaders.push(['X-PANW-Region', region as string]);
    // ourHeaders.push(['X-PANW-Region', 'americas']); // TODO: not needed later remove it, only for integration purpose
  }

  if (component === Products.FAWKES || component === Products.FAWKES_JWT) {
    ourHeaders.push(['x-auth-jwt', jwt]);
  }

  const params: RequestInit = { headers: ourHeaders };

  if (initParams.modifiers) {
    const modifiers = initParams.modifiers;
    const headers: AppAcclMap<string> = params.headers as AppAcclMap<string>;
    if (headers) {
      if (modifiers.mimeType !== undefined && modifiers.mimeType.length > 0) {
        headers['Content-Type'] = modifiers.mimeType;
      } else {
        delete headers['Content-Type'];
      }

      if (modifiers.accept !== undefined && modifiers.accept.length > 0) {
        headers['Accept'] = modifiers.accept;
      } else {
        delete headers['Accept'];
      }
    }
    if (modifiers.noAuthKey) {
      delete params.credentials;
    }
  }

  const method = initParams.method.toLowerCase();
  Logger.info(`backend.initRequest:  method: ${method}, RequestMethods: ${RequestMethods[method]}`)
  params.method = RequestMethods[method];

  return params;
}

export async function ZGet( url: string, params: RestAPI | string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, params: makeQueryParams( '', params ), method: RequestMethods.get}
  return await HttpCommand(details, component);
}

export async function ZPost(url: string, restParams: string | FormData, modifiers?: RestModifiers, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.post}
  return await HttpCommand(details, component);
}

export async function ZDelete(url: string, restParams: string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.delete}
  return await HttpCommand(details, component);
}

export async function ZPut(url: string, restParams: string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.put}
  return await HttpCommand(details, component);
}

export async function ZPatch(url: string, restParams: string, component?: Products): Promise<ZRestResponse> {
  const details: InitRequestParams = {url, restParams, method: RequestMethods.patch}
  return await HttpCommand(details, component);
}

const buildFetchErrorStatus = (e: FetchStatus): FetchStatus => {
  let errState: FetchStatus = {status: {}}
  if (! e.status) {
    const STRs = getStrings() as AppAcclMap<string>;
    errState = {
      status: {
        error_message: STRs.serverError,
        ok: false,
        http_status: 500
        }
    }
  } else {
    errState = e;
  }

  return errState;
}

export async function HttpCommand (requestDetails: InitRequestParams, component?: Products): Promise<ZRestResponse> {
  const params = await initRequest(requestDetails, component);
  let fullUrl = '';
  if (requestDetails.url?.indexOf('#') === -1) {
    fullUrl = makeUrl(requestDetails.url, component);
  } else {
    fullUrl = (requestDetails.url as string).slice(1);
  }
  if (fullUrl.indexOf('v1') > -1 && fullUrl.indexOf('v2') > -1) {
    fullUrl = fullUrl.replace('/sse/app_accl/v1', '');
  }

    const body =  ((typeof requestDetails?.restParams === 'string' && requestDetails.restParams.length > 0)) ? 
                    requestDetails.restParams : '';

  if (body?.length > 0) {
    params.body = body;
  }

  const copyResp = (response: Response): FetchStatus => {
    let status: FetchStatus = {} as FetchStatus;
    if (typeof(response.status) === 'number') {
      status = { 
        status: {
          http_status: response.status,
          'http_status_message': response.statusText,
          ok: response.ok,
        }
      }
    } else {
      status = response as FetchStatus;
    }
    return status;
  }

  // console.log(`${Date().toString()}: Put url: ${url}; parms: ${restParams} `);
  let respCopy = {};
  const promise = fetch(fullUrl, params)
    .then(response => {
        respCopy = copyResp(response)
        const lData = response.json();
        return lData;
    }).catch(e => {
      Logger.error('Error with HTTP request:', JSON.stringify(respCopy));
      throw buildFetchErrorStatus(respCopy as FetchStatus)
    })

  return promise;
}
