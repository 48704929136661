/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'
import type { NavParams } from '@sparky/framework';
import { hasLicense } from './shared/licensing';


const phase1Nav: Object = {
        navigation: [{
        key: 'appAccelWorkflows',
        title: 'Workflows',
        expandable: true,
        path: '/workflows',
        noRBACheck: true,
        exact: false,
        mergeNav: true,
        children: [{
            key: 'app-accel-config',
            title: 'App Acceleration',
            path: '/workflows/app-acceleration',
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
            component: () => import('./AppAccelRoutes'), // any component must be dynamically import like this
            children: [{
                key: 'app-accel-config1',
                title: 'Configuration',
                path: '/workflows/app-acceleration',
                noRBACheck: true,
                },
            ]
        }],
    }],
    exposes: {
        default: {
            CertDropDownWrapper:   () => import('./shared/CertDropdown'),
            AppAccelerationToggleWrapper: () => import('./app/AppAccelerationToggleWrapper'),
            AppAccelerationTableWrapper: () => import('./app/AppAccelerationTableWrapper'),
        }
    }
}

const phase1SDWANNav: Object = {
    navigation: [],
    exposes: {
        default: {
            CertDropDownWrapper:   () => import('./shared/CertDropdown'),
            AppAccelerationToggleWrapper: () => import('./app/AppAccelerationToggleWrapper'),
            AppAccelerationTableWrapper: () => import('./app/AppAccelerationTableWrapper'),
        }
    }
}

export default function init({ vars, sparkyFramework }: NavParams) {
    return async function tsgLoad() {
        let template: Object | undefined = undefined;
        try {
            const prod = sparkyFramework.getFrameworkVar('production');
            console.log(vars.compat_tsg_ids)
            const useTemplate = await hasLicense();
            console.info('phase1Nav - ', phase1Nav);
            // template = useTemplate ? templateNavWithSubNav : undefined;
            // template = useTemplate ? simplePhase1Nav : undefined;
            template = useTemplate ? phase1Nav : phase1SDWANNav;
        }
        catch(e) {
            template = undefined;
        }

        return (template) 
    };
};